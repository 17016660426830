<template>
    <div>
        <CSBreadcrumb />
        <div class="filter-panel">
            <CSSelect style="margin-right: 0;">
                <el-date-picker
                    type="datetime"
                    style="width: 184px;"
                    v-model="queryParams.startDate"
                    format="yyyy-MM-dd HH:mm"
                    value-format="yyyy-MM-dd HH:mm"
                    placeholder="请选择"
                    prefix-icon="el-icon-time"
                    :editable="false"
                ></el-date-picker>
            </CSSelect>
            <span class="inline-gray"></span>
            <CSSelect>
                <el-date-picker
                    type="datetime"
                    style="width: 184px;"
                    format="yyyy-MM-dd HH:mm"
                    v-model="queryParams.endDate"
                    value-format="yyyy-MM-dd HH:mm"
                    placeholder="请选择"
                    prefix-icon="el-icon-time"
                    :editable="false"
                ></el-date-picker>
            </CSSelect>
            <CSSelect>
                <select v-model="queryParams.registerId">
                    <option value="">登记人不限</option>
                    <option v-for="staff in staffList"
                        :value="staff.id" :key="staff.id"
                    >
                        {{staff.name}}
                    </option>
                </select>
            </CSSelect>
            <input type="text" placeholder="搜索登记说明" class="cs-input" v-model="queryParams.description">
            <button class="btn btn-primary" @click="getLogList()">查询</button>
        </div>

        <div class="result-panel">
            <CSTable :thead-top="filterHeight">
                <template v-slot:thead>
                    <tr>
                        <th>登记时间</th>
                        <th>登记人</th>
                        <th>登记说明</th>
                        <th>图片</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <template v-if="logList.length > 0">
                        <tr v-for="log in logList" :key="log.id">
                            <td>{{log.createTime || '-'}}</td>
                            <td>{{log.userName}}</td>
                            <td>
                                {{log.description || '-'}}
                                <!--                                <div class="allow-click" style="max-width: 200px;" v-if="log.description" @click="lookDescription(log.description)">
                                                                    {{log.description}}
                                                                </div>

                                                                <template v-else>-</template>-->
                            </td>
                            <td>
                                <span class="allow-click" v-if="log.picture" @click="lookPicture(log.picture)">查看</span>
                                <template v-else>-</template>
                            </td>
                        </tr>
                    </template>
                </template>
            </CSTable>
            <Pagination />
        </div>
        <ViewImageModal />
    </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import ViewImageModal from "@/components/ViewImageModal";
import {queryRegister} from "@/requestUrl";
import dayjs from "dayjs";
import Pagination from "@/components/Pagination";
import CSTable from "@/components/common/CSTable";

export default {
    name: "LocationCheckInLog",
    props: {
        id: Number
    },
    components: {
        CSTable,
        CSSelect,
        ViewImageModal,
        CSBreadcrumb,
        Pagination,
    },
    data() {
      return {
          filterHeight: 0,
            logList: [],
            staffList: [],
            queryParams: {
                startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD 00:00'),
                endDate: dayjs().format('YYYY-MM-DD 23:59'),
                registerId: '',
                description: '',
            }
      }
    },
    async mounted() {
        this.getLogList();
        this.staffList = await this.$vc.getStaffList({
            regionCode: this.$vc.getCurrentRegion().code,
            departmentCode: '',
            jobLevel: '',
            isOnTheJob: true
        });
        this.$vc.on(this.$route.path, 'pagination_page', 'event', page => {
            this.getLogList(page);
        })
        this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        window.addEventListener('resize', () => {
            this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        });
        window.addEventListener("keydown",this.keyCodeEnter);

    },
    beforeDestroy(){
        window.removeEventListener("keydown",this.keyCodeEnter);
    },
    deactivated(){
         window.removeEventListener("keydown",this.keyCodeEnter);
    },
    methods: {
        keyCodeEnter(e){
            if(e.keyCode == 13){
                  this.getLogList();
            }
        },
        /**
         * 查看登记说明
         * @param {String} description 登记说明
         * */
        lookDescription(description) {
            this.$CSDialog.alert({
                title: '查看登记说明',
                message: description,
            })
        },
        /**
         * 查看图片
         * @param {String} picture 图片地址
         * */
        lookPicture(picture) {
            this.$vc.emit(this.$route.path, "viewImageModal", "view", JSON.parse(picture))
        },
        getLogList(pageNo = 1, pageSize = 10) {
            console.log(this.id)
            this.$fly.post(queryRegister, {
                regionCode: this.$vc.getCurrentRegion().code,
                locationId: this.id,
                ...this.queryParams,
                pageNo,
                pageSize
            })
            .then(res => {
                if (res.code !== 0) {
                    return ;
                }
                if (pageNo === 1) {
                    this.$vc.emit(this.$route.path, 'pagination', 'init', {
                        total: res.data.total,
                        currentPage: 1,
                        pageSize,
                    })
                }
                this.logList = res.data.datas;
            })
        }
    }
}
</script>

<style scoped>

</style>
